import React, { useState, useEffect } from "react";
import Layout from "components/Layout/Layout";

import Image from "material-ui-image";

import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import { productInfo, productDetails } from "./ProductInfo";
import b1im1Bg from "img/bike1-.jpg";

const Products = (props) => {
  const products = productInfo(0);

  const onRedirect = (newIndex) => {
    props.history.push(`/product/${newIndex + 1}`);
    //history.push( `/product/${newIndex}`);
  };

  const useStyles = makeStyles({
    root: {
      maxWidth: 345,
    },
  });

  const classes = useStyles();

  //   useEffect(() => {
  //     myRef.current.scrollTo(0, 0);
  //     console.log("test", products);
  //   }, [products]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  //const productDetail = productDetails(parseInt(props.match.params.productId));

  return (
    <Layout
      title="Product"
      description="Product description"
      className="container col-md-8 offset-md-2"
    >
      <div className="row m-0 lr justify-content-center">
        {products.map((item, index) => {
          return (
            <div key={index} class="col-md-5 col-sm-5 col-11 m-3">
              <Card className={classes.root} className="w-100 m-3">
                <CardActionArea>
                  {/* <CardMedia
              component="img"
              alt="Contemplative Reptile"
              height="140"
              image="../img/bike1-.jpg"
              title="Contemplative Reptile"
            /> */}
                  <Image src={item.bikes[0].src} animationDuration={500} />

                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      {item.title}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      {item.descriptionText}
                    </Typography>
                  </CardContent>
                </CardActionArea>
                <CardActions>
                  {/* <Button size="small" color="primary">
                    Share
                  </Button> */}
                  <Button
                    size="small"
                    color="primary"
                    onClick={() => onRedirect(index)}
                  >
                    Learn More
                  </Button>
                </CardActions>
              </Card>
            </div>
          );
        })}
      </div>
    </Layout>
  );
};

export default Products;
