import React from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

import Layout from "components/Layout/Layout";
// import Data from "./data.json";
import im1 from "./img/bike1.jpg";
import im2 from "./img/bike2.jpg";
import im3 from "./img/bike3.jpg";
import im1Bg from "./img/bike1-.png";
import im2Bg from "./img/bike2-.png";
import im3Bg from "./img/bike3-.png";

import { Slide } from "react-slideshow-image";

import { useHistory } from "react-router-dom";

const App = () => {
  const history = useHistory();
  const getContent = () => {
    if (history.location.pathname !== "/") {
      return (
        history.location.pathname[1].toUpperCase() +
        history.location.pathname.slice(2)
      );
    }
    return "";
  };
  // const slideImages = [
  //     "./img/discount1.jpg",
  //     "./img/discount2.png",
  //     "./img/discount3.jpg",
  //     "./img/discount4.png",
  //     "./img/discount5.jpg",
  // ];

  const slideImages = [
    {
      name: "Mecer E-bike white",
      description: "E-bike white",
      slug: "product/1",
      slideImg: im1,
      img: im1Bg,
    },
    {
      name: "Mecer E-bike 4773",
      description: "E-bike 4773",
      slug: "product/2",
      slideImg: im2,
      img: im2Bg,
    },
    {
      name: "Mecer E-bike 4796",
      description: "E-bike 4796",
      slug: "product/3",
      slideImg: im3,
      img: im3Bg,
    },
  ];

  const properties = {
    duration: 3000,
    transitionDuration: 500,
    infinite: true,
    indicators: true,
    arrows: true,
    pauseOnHover: true,
    onChange: (oldIndex, newIndex) => {
      console.log(`slide transition from ${oldIndex} to ${newIndex}`);
    },
  };

  const onRedirect = (newIndex) => {
    history.push(`/${slideImages[newIndex].slug}`);
  };

  return (
    <Layout>
      {getContent() === "" && (
        <div className="slide-container">
          <Slide {...properties}>
            {slideImages.map((item, key) => (
              <div key={key} className="each-slide ">
                <div>
                  <img
                    src={item.slideImg}
                    className=" d-inline-block"
                    style={{ width: "100%" }}
                    // alt={`Slide ${key + 1}`}
                    alt={item.name}
                    onClick={() => onRedirect(key)}
                  />
                </div>
              </div>
            ))}
          </Slide>

          {slideImages.map((item, key) => (
            <div
              className="container-fluid itemDivContainer"
              key={key}
              style={{
                background: key % 2 === 0 ? "#efefef" : "#f4f4f4",
              }}
            >
              <div
                className={`col-md-6 col-sm-8 col-12  ${
                  key % 2 === 0 ? "float-left" : "float-right"
                }`}
              >
                <img
                  src={item.img}
                  className="col-10"
                  // alt={`Slide ${key + 1}`}
                  alt={item.name}
                  onClick={() => onRedirect(key)}
                />
              </div>

              <div className="col-md-6 col-sm-4 col-12 row  moreInfoDiv">
                <h3>{item.name}</h3>
                <p>{item.description}</p>
                <button
                  className="btn btn-lg btn-info"
                  onClick={() => onRedirect(key)}
                >
                  More Info
                </button>
              </div>
            </div>
          ))}
        </div>
      )}
    </Layout>
  );
};

export default App;
