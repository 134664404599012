import React from "react";

import Navbar from "./Navbar/Navbar";
import Footer from "./Footer/Footer";

// import HomeButtons from 'components/Layout/HomeButtons/HomeButtons';
import "./Layout.css";

const Layout = ({
    title = "Title",
    description = "Description",
    className = "",
    children,
    cartSize,
}) => {
    return (
        <div className="telebike">
            <Navbar cartSize={cartSize} />

            {/* <HomeButtons /> */}

            {/* <div className="jumbotron">
                <h2>{title}</h2>
                <p className="lead">{description}</p>
            </div>
            <div className={className}>{children}</div> */}
            <div className="contentt">{children}</div>
            <Footer />
        </div>
    );
};

export default Layout;
