import React, { Fragment } from "react";

import { Link, withRouter } from "react-router-dom";
import "./NavbarButtons.css";

import { isAuthenticated, signout } from "auth";

const isActive = (history, path) => {
  if (history.location.pathname === path) {
    return "active";
    // return { color: "#ff9900" };
  } else {
    return "";
    // return { color: "#ffffff" };
  }
};

const NavbarButtons = ({ history }) => {
  // const btnArr = [
  //     { name: "Home", url: "/", icon: "home" },
  //     {
  //         name: "Sign up as Business",
  //         url: "/sign-up-business",
  //         icon: "briefcase",
  //     },
  //     { name: "Login", url: "/login", icon: "sign-in" },
  //     { name: "Sign Up", url: "/sign-up", icon: "user-plus" },
  // ];

  return (
    <React.Fragment>
      <Link className={`nav-link ${isActive(history, "/")}`} to="/">
        <i className="fa fa-home  mr-1"></i>
        Home
      </Link>

      {isAuthenticated() && isAuthenticated().user.role === 0 && (
        <li className="nav-item">
          <Link
            className={`nav-link ${isActive(history, "/user/dashboard")}`}
            to="/user/dashboard"
          >
            <i className="fa fa-address-card  mr-1"></i>
            Dashboard
          </Link>
        </li>
      )}

      {isAuthenticated() && isAuthenticated().user.role === 1 && (
        <li className="nav-item">
          <Link
            className={`nav-link ${isActive(history, "/admin/dashboard")}`}
            to="/admin/dashboard"
          >
            Dashboard
          </Link>
        </li>
      )}

      {!isAuthenticated() && (
        <Fragment>
          <Link
            className={`nav-link ${isActive(history, "/products")}`}
            to="/products"
          >
            <i className="fa fa-briefcase  mr-1"></i>
            Electric Bike
          </Link>
          <Link
            className={`nav-link ${isActive(history, "/about-us")}`}
            to="/about-us"
          >
            <i className="fa fa-sign-in  mr-1"></i>
            About Us
          </Link>
          <Link
            className={`nav-link ${isActive(history, "/contact-us")}`}
            to="/contact-us"
          >
            <i className="fa fa-user-plus  mr-1"></i>
            Contact Us
          </Link>
          {/* <Link
                        className={`nav-link ${isActive(history, "/login")}`}
                        to="/login"
                    >
                        <i className="fa fa-sign-in  mr-1"></i>
                        Login
                    </Link>
                    <Link
                        className={`nav-link ${isActive(history, "/signup")}`}
                        to="/sign-up"
                    >
                        <i className="fa fa-user-plus  mr-1"></i>
                        Sign Up
                    </Link> */}
        </Fragment>
      )}

      {isAuthenticated() && (
        <Fragment>
          <Link
            className={`nav-link ${isActive(history, "/user/dashboard")}`}
            to="/user/dashboard"
          >
            <i className="fa fa-user-plus  mr-1"></i>
            Dashboard
          </Link>
          <li className="nav-item">
            <span
              className="nav-link"
              style={{ cursor: "pointer" }}
              onClick={() =>
                signout(() => {
                  history.push("/");
                })
              }
            >
              <i className="fa fa-sign-out  mr-1"></i>
              Sign out
            </span>
          </li>
        </Fragment>
      )}
    </React.Fragment>
  );
};

export default withRouter(NavbarButtons);
