import React, { useState } from 'react';
import './Navbar.css';
import logo from 'img/telebike.png';
// import Language from "components/Layout/Navbar/Language/Language";
// import CartIcon from "components/Layout/Navbar/CartIcon/CartIcon";
import SideDrawer from 'components/SideDrawer/SideDrawer';
import NavbarButtons from './NavbarButtons/NavbarButtons';
import Backdrop from 'components/Backdrop/Backdrop';
import SearchBar from 'components/SearchBar/SearchBar';

import { Link } from 'react-router-dom';

const Navbar = ({ cartSize }) => {
    const [drawerIsOpen, setDrawerIsOpen] = useState(false);
    const [drawerSide, setDrawerSide] = useState('left');
    const [drawerContent, setDrawerContent] = useState('');

    const openDrawerHandler = (drawerType, side) => {
        let content = '';
        if (drawerType === 'menu') {
            content = (
                <React.Fragment>
                    <NavbarButtons />

                    <div className="d-inline-block w-100">
                        <li className="cartIconDiv bg-success">
                            <i className="fa fa-user" />
                            <span className="cartText">My Account</span>
                        </li>
                        <li className="cartIconDiv bg-success">
                            <i className="fa fa-shopping-cart" />
                            <span className="cartText">My Cart</span>
                        </li>
                    </div>
                </React.Fragment>
            );
        } else if (drawerType === 'filter') {
            content = <span>Filter by value</span>;
        } else {
            content = <span>Sort by value</span>;
        }

        setDrawerSide(side);
        setDrawerContent(content);
        setDrawerIsOpen(true);
    };

    const closeDrawerHandler = () => {
        setDrawerIsOpen(false);
    };
    return (
        <div className="container-full telebike">
            <nav className="navbar navbar-expand-md navbar-expand-sm  bg-tele">
                <div className="container-fluid">
                    <Link className="navbar-brand logo" to="/">
                        <img src={logo} className="d-inline-block" alt="" />
                    </Link>
                    <button
                        className="navbar-toggler round-toggle"
                        type="button"
                        data-toggle="collapse"
                        data-target="#navbarSupportedContent"
                        // onClick={() => openDrawerHandler('menu', 'right')}
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <span className="navbar-toggler-icon">
                            <i className="fa fa-bars"></i>
                        </span>
                    </button>
                    <div
                        className="collapse navbar-collapse"
                        id="navbarSupportedContent"
                    >
                        <SearchBar placeHolder="What would you like?" />
                        <ul className="navbar-nav ml-auto">
                            <NavbarButtons />
                            <li className="cartIconDiv bg-success">
                                <i className="fa fa-user" />
                                <span className="cartText">My Account</span>
                            </li>
                            <li className="cartIconDiv bg-success">
                                <i className="fa fa-shopping-cart" />
                                <span className="cartText">My Cart</span>
                            </li>
                            {/* <Language /> */}
                        </ul>
                    </div>
                </div>
            </nav>
            {drawerIsOpen && <Backdrop onClick={closeDrawerHandler} />}
            <SideDrawer
                show={drawerIsOpen}
                onClick={closeDrawerHandler}
                side={drawerSide}
            >
                <nav className="navbar navbar-expand-md navbar-expand-sm bg-tele">
                    <Link className="navbar-brand logo mb-2" to="/">
                        <img src={logo} className="" alt="logo" />
                    </Link>
                    <i className="fa fa-times"></i>
                </nav>
                <div className="drawerButtons">{drawerContent}</div>
            </SideDrawer>
        </div>
    );
};

export default Navbar;
