import React from 'react';

import './Footer.css';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <footer className="main-footer text-center bg-tele">
            Copyright Reserved.
            <Link to="/">
                <b>tele724.online</b>
            </Link>
        </footer>
    );
};

export default Footer;
