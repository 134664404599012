import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import PrivateRoute from "auth/PrivateRoute";
import Signup from "components/Signup/Signup";
import Signin from "components/Signin/Signin";

import Dashboard from "user/Dashboard";
import SignupBusiness from "components/SignupBusiness/SignupBusiness";
import Details from "components/Details/Details";
import Product from "components/Product/Product";
import Products from "components/Product/Products";

import App from "App";

// import Home from "core/Home";

const Routes = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={App} />
        <Route path="/login" exact component={Signin} />
        <Route path="/sign-up" exact component={Signup} />
        <Route path="/sign-up-business" exact component={SignupBusiness} />
        <PrivateRoute path="/user/dashboard" exact component={Dashboard} />
        <Route path="/products" exact component={Products} />
        {/* <Route path="/store/:storeId" exact component={StorePage} /> */}
        <Route path="/product/:productId" exact component={Product} />
        <Route path="/:detailSlug" exact component={Details} />

        <Route path="/email/confirm/:key" exact component={Signin} />
        <Route render={() => <h1>Page not found</h1>} />

        {/*<AdminRoute
                    path="/admin/dashboard"
                    exact
                    component={AdminDashboard}
                />
                <AdminRoute
                    path="/create/category"
                    exact
                    component={AddCategory}
                />
                <AdminRoute
                    path="/create/product"
                    exact
                    component={AddProduct}
                />
                <Route path="/product/:productId" exact component={Product} />
                <Route path="/cart" exact component={Cart} />
                <AdminRoute path="/admin/orders" exact component={Orders} />
                <PrivateRoute
                    path="/profile/:userId"
                    exact
                    component={Profile}
                />
                <PrivateRoute
                    path="/admin/products"
                    exact
                    component={ManageProducts}
                />
                <AdminRoute
                    path="/admin/products"
                    exact
                    component={ManageProducts}
                /> */}
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
