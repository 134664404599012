import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
// import App from "./App";
import Routes from "./Routes";

//package json private in altina yapistir, django icin
// "proxy": "http://94.100.50.222:8000/",

ReactDOM.render(<Routes />, document.getElementById("root"));
