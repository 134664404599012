import b1im1Bg from "img/bike1-.jpg";

import b2im1Bg from "img/bike2a.jpg";
import b2im2Bg from "img/bike2b.jpg";
import b2im3Bg from "img/bike2c.jpg";
import b2im4Bg from "img/bike2d.jpg";
import b2im5Bg from "img/bike2e.jpg";

import b3im1Bg from "img/bike3a.jpg";
import b3im2Bg from "img/bike3b.jpg";
import b3im3Bg from "img/bike3c.jpg";
import b3im4Bg from "img/bike3d.jpg";
import b3im5Bg from "img/bike3e.jpg";
import b3im6Bg from "img/bike3f.jpg";
export const productInfo = (productId) => {
  switch (productId) {
    case 1:
      return [
        {
          title: "Mecer E-bike white",
          price: "1 000,00€",
          priceAfterDiscount: "950,00€",
          model: "Mecer E-bike white",
          bikes: [{ id: 1, src: b1im1Bg, title: "bike1", description: "" }],
          selectedBike: {
            id: 1,
            src: b1im1Bg,
            title: "bike1",
            description: "",
          },
          descriptionTitle:
            "Product presentation: MECER 26 'electric city bike - Aluminum frame with low spanning - Autonomy 55 km - 7 speed shimano - Assembled in France - White",
          descriptionText:
            "The Mecer electric assistance bike allows you to pedal faster, further and effortlessly thanks to its 36v 10.4 AH battery and its 350 W motor. With a low spanning frame and 26 inch wheels, the electric bike is suitable for small and large sizes. The bike is delivered in a cardboard box ready to roll, you will not need to assemble it because it was previously assembled and tested in our workshops. Electric assisted city bike 26 '', 36V, 11Ah, white",
        },
      ];
      break;
    case 2:
      return [
        {
          title: "Mecer E-bike 4773",
          price: "1 000,00€",
          priceAfterDiscount: "950,00€",
          model: "E-bike 4773",
          bikes: [
            { id: 1, src: b2im1Bg, title: "bike1", description: "" },
            { id: 2, src: b2im2Bg, title: "bike2", description: "" },
            { id: 3, src: b2im3Bg, title: "bike3", description: "" },
            { id: 4, src: b2im4Bg, title: "bike4", description: "" },
            { id: 5, src: b2im5Bg, title: "bike5", description: "" },
          ],
          selectedBike: {
            id: 1,
            src: "img/bike2a.jpg",
            title: "bike1",
            description: "",
          },
          descriptionTitle:
            "Product presentation: MECER 20 'electric folding bike - Aluminum frame - Autonomy 55km - 7 speed shimano - Assembled in France - White",
          descriptionText:
            "White folding electric bike suspended with an excellent value for money. It is equipped with a suspension fork and disc brakes for excellent comfort of use. Internal removable battery in the frame of 36V 10 AH and 20 inch wheels. With a top speed of 25km / h effortlessly and minimal bulk once folded, go fast without suffering, without sweating and take it everywhere. 20 '' Electric Foldable Bike, 36V, 11Ah, white",
        },
      ];
      break;
    case 3:
      return [
        {
          title: "Mecer E-bike 4796",
          price: "1 000,00€",
          priceAfterDiscount: "950,00€",
          model: "Mecer E-bike 4796",
          bikes: [
            { id: 1, src: b3im1Bg, title: "bike1", description: "" },
            { id: 2, src: b3im2Bg, title: "bike2", description: "" },
            { id: 3, src: b3im3Bg, title: "bike3", description: "" },
            { id: 4, src: b3im4Bg, title: "bike4", description: "" },
            { id: 5, src: b3im5Bg, title: "bike5", description: "" },
            { id: 6, src: b3im6Bg, title: "bike6", description: "" },
          ],
          selectedBike: {
            id: 1,
            src: "img/bike3a.jpg",
            title: "bike1",
            description: "",
          },
          descriptionTitle:
            "Mecer - foldable electric mountain bike battery 36V 10 Ah Black",
          descriptionText:
            "The black Mecer foldable electric mountain bike is very popular with motorhomes and mountain bikers. The folding electric bike is easy to transport in a car trunk or on public transport. Fully suspended with excellent value for money it has an internal removable battery as part of 36V 8.8 Ah and 26 inch wheels. It will thus go unnoticed difficult to know that it is an electric mountain bike.",
        },
      ];
      break;
    default:
      return [
        {
          title: "Mecer E-bike white",
          price: "1 000,00€",
          priceAfterDiscount: "950,00€",
          model: "Mecer E-bike white",
          bikes: [{ id: 1, src: b1im1Bg, title: "bike1", description: "" }],
          selectedBike: {
            id: 1,
            src: b1im1Bg,
            title: "bike1",
            description: "",
          },
          descriptionTitle:
            "Product presentation: MECER 26 'electric city bike - Aluminum frame with low spanning - Autonomy 55 km - 7 speed shimano - Assembled in France - White",
          descriptionText:
            "The Mecer electric assistance bike allows you to pedal faster, further and effortlessly thanks to its 36v 10.4 AH battery and its 350 W motor. With a low spanning frame and 26 inch wheels, the electric bike is suitable for small and large sizes. The bike is delivered in a cardboard box ready to roll, you will not need to assemble it because it was previously assembled and tested in our workshops. Electric assisted city bike 26 '', 36V, 11Ah, white",
        },
        {
          title: "Mecer E-bike 4773",
          price: "1 000,00€",
          priceAfterDiscount: "950,00€",
          model: "E-bike 4773",
          bikes: [
            { id: 1, src: b2im1Bg, title: "bike1", description: "" },
            { id: 2, src: b2im2Bg, title: "bike2", description: "" },
            { id: 3, src: b2im3Bg, title: "bike3", description: "" },
            { id: 4, src: b2im4Bg, title: "bike4", description: "" },
            { id: 5, src: b2im5Bg, title: "bike5", description: "" },
          ],
          selectedBike: {
            id: 1,
            src: "img/bike2a.jpg",
            title: "bike1",
            description: "",
          },
          descriptionTitle:
            "Product presentation: MECER 20 'electric folding bike - Aluminum frame - Autonomy 55km - 7 speed shimano - Assembled in France - White",
          descriptionText:
            "White folding electric bike suspended with an excellent value for money. It is equipped with a suspension fork and disc brakes for excellent comfort of use. Internal removable battery in the frame of 36V 10 AH and 20 inch wheels. With a top speed of 25km / h effortlessly and minimal bulk once folded, go fast without suffering, without sweating and take it everywhere. 20 '' Electric Foldable Bike, 36V, 11Ah, white",
        },
        {
          title: "Mecer E-bike 4796",
          price: "1 000,00€",
          priceAfterDiscount: "950,00€",
          model: "Mecer E-bike 4796",
          bikes: [
            { id: 1, src: b3im1Bg, title: "bike1", description: "" },
            { id: 2, src: b3im2Bg, title: "bike2", description: "" },
            { id: 3, src: b3im3Bg, title: "bike3", description: "" },
            { id: 4, src: b3im4Bg, title: "bike4", description: "" },
            { id: 5, src: b3im5Bg, title: "bike5", description: "" },
            { id: 6, src: b3im6Bg, title: "bike6", description: "" },
          ],
          selectedBike: {
            id: 1,
            src: "img/bike3a.jpg",
            title: "bike1",
            description: "",
          },
          descriptionTitle:
            "Mecer - foldable electric mountain bike battery 36V 10 Ah Black",
          descriptionText:
            "The black Mecer foldable electric mountain bike is very popular with motorhomes and mountain bikers. The folding electric bike is easy to transport in a car trunk or on public transport. Fully suspended with excellent value for money it has an internal removable battery as part of 36V 8.8 Ah and 26 inch wheels. It will thus go unnoticed difficult to know that it is an electric mountain bike.",
        },
      ];
      break;
  }
};
export const productDetails = (productId) => {
  switch (productId) {
    case 1:
      return [
        {
          title: "General product information",
          array: [
            {
              name: "Mark",
              value: "MECER",
            },
            {
              name: "Product Name",
              value: "MECER City bike 26 'electric - Aluminum frame with",
            },
            {
              name: "Category",
              value: "ELEC ASSISTANCE BIKE",
            },
          ],
        },
        {
          title: "General",
          array: [
            {
              name: "Type of product",
              value: "electric city bike",
            },
            {
              name: "Models",
              value: "MEB-CB-26-W",
            },
            {
              name: "Sports",
              value: "Leisure cycling Road cycling",
            },
            {
              name: "Materials",
              value: "Aluminum",
            },
            {
              name: "Colors",
              value: "White",
            },
            {
              name: "Certifications and standards",
              value:
                "EN 15194: 2009 + A11: 2011 European standard EPAC EN15194",
            },
            {
              name: "Weight (up to)",
              value: "100 kg",
            },
            {
              name: "Age (From)",
              value: "13 years",
            },
            {
              name: "Child size",
              value: "150 cm",
            },
          ],
        },
        {
          title: "Product Info",
          array: [
            {
              name: "Frame size",
              value: '26 " aluminum frame',
            },
            {
              name: "Wheel sizes",
              value: '26 "',
            },
            {
              name: "Type of bike",
              value: "VAE",
            },
            {
              name: "Compatible land (s)",
              value: "Asphalt Paths and trails",
            },
          ],
        },
        {
          title: "Characteristics",
          array: [
            {
              name: "Product Description",
              value:
                "MECER Electrically assisted city bike, aluminum frame, 36 V, 11 Ah lithium batteries with hill start assistance and stop lights Maximum speed: 25 Km / h  DESIGN and ASSEMBLY in France",
            },
            {
              name: "More product",
              value:
                "The MECER bike has a trigger for hill start without difficulty whatever your age, LED STOP LIGHTS + powerful LED LIGHTS AV + AR, Removable saddle, luggage rack with spring fixing system. The bike is one of the only ones that can be used by people the size of 1.50m.",
            },
            {
              name: "Number and type of storage",
              value:
                "luggage rack with fastening system, the basket at the front is optional",
            },
            {
              name: "Comfort",
              value: "tilting saddle",
            },
          ],
        },
        {
          title: "Technical characteristics",
          array: [
            {
              name: "Frame / Fork",
              value: "Aluminum low spanning frame Aluminum suspension fork",
            },
            {
              name: "Suspension / shock absorber",
              value:
                "With its suspension at the front your bike brings you comfort in all circumstances",
            },
            {
              name: "Tire",
              value: "26 inch (26 x 1.5)",
            },
            {
              name: "Brake (s)",
              value: "V brake brakes front and rear pads",
            },
            {
              name: "Handlebar / Handle",
              value:
                "Handlebar type Dutch curved forward and fully adjustable, Handle in semi-rigid rubber very resistant",
            },
            {
              name: "Direction / Gallows",
              value: "Height adjustable aluminum stem",
            },
            {
              name: "Number of speeds",
              value: "7 speed",
            },
            {
              name: "Derailleur / Cassette",
              value: "Shimano 7-speed rear derailleur",
            },
            {
              name: "Pedals / Bottom Bracket",
              value: "Non-slip pedals",
            },
            {
              name: "Saddle / Seatpost",
              value: "Tilting and removable rod Minimum seat height 80 cm",
            },
            {
              name: "Crutch",
              value: "Side stand",
            },
            {
              name: "Stabilizers",
              value: "With stabilizer",
            },
            {
              name: "Horn",
              value: "mechanical",
            },
            {
              name: "Motor position",
              value: "Rear wheel",
            },
          ],
        },
        {
          title: "Food",
          array: [
            {
              name: "Feed type",
              value: "Drums",
            },
            {
              name: "Drums",
              value:
                "Removable 36V 11 Ah Samsung Lithium battery with anti-theft charger: 3 to 6 hours",
            },
            {
              name: "Autonomy",
              value: "30 to 55Km",
            },
            {
              name: "Engine",
              value: "MECER 36V motor, 350W, flanged to 250W",
            },
          ],
        },
        {
          title: "Dimensions and Weight",
          array: [
            {
              name: "Dimensions",
              value: "Adjustable saddle up to user size 1M5",
            },
            {
              name: "Weight",
              value: "20 kg",
            },
          ],
        },
        {
          title: "Various",
          array: [
            {
              name: "Mounting",
              value: "Delivered assembled",
            },
            {
              name: "Mounting type",
              value:
                "Delivered 98% assembled: Reassemble the front wheel with the front mudguard, handlebar to be simply plugged into its axle and tighten it, mount the pedals",
            },
            {
              name: "Usage tips",
              value: "Recommended user size: from 1.5m up to 2m",
            },
            {
              name: "Native country",
              value: "France",
            },
          ],
        },
        {
          title: "Your guarantees included for the products sold by Cdiscount",
          array: [
            {
              name: "Guarantee (²)",
              value: "2 years - parts, labor and travel",
            },
            {
              name: "Observations",
              value:
                "- See specific terms and conditions in the GTC for second-hand products. - These guarantees are not applicable to products sold for the marketplace.  As well as products sold to professionals and / or for professional use. Please refer to the seller's terms and conditions.",
            },
          ],
        },
      ];

      break;

    case 2:
      return [
        {
          title: "General product information",
          array: [
            {
              name: "Mark",
              value: "MECER",
            },
            {
              name: "Product Name",
              value: "MECER Folding bike 20 'electric - Aluminum frame - Aut",
            },
            {
              name: "Category",
              value: "ELEC ASSISTANCE BIKE",
            },
          ],
        },
        {
          title: "General",
          array: [
            {
              name: "Type of product",
              value: "electric city bike",
            },
            {
              name: "Sports",
              value: "Leisure cycling Road cycling",
            },
            {
              name: "Colors",
              value: "Black",
            },
            {
              name: "Certifications and standards",
              value: "EN 15194: 2009 + A1: 2011",
            },
            {
              name: "Weight (up to)",
              value: "110 kg",
            },
            {
              name: "Age (From)",
              value: "13 years",
            },
          ],
        },
        {
          title: "Product Info",
          array: [
            {
              name: "Frame size",
              value: '20 "foldable aluminum frame',
            },
            {
              name: "Type of bike",
              value: "VAE",
            },
            {
              name: "Compatible land (s)",
              value: "Asphalt Paths and trails",
            },
          ],
        },
        {
          title: "Characteristics",
          array: [
            {
              name: "Product Description",
              value:
                "MECER Foldable Bike 20 with electric assistance aluminum frame, disc brakes AV + AR, lithium batteries 36 V, 11 Ah, not visible integrated in the frame and removable, with an assistance for starting on a hill, equipped with luggage rack DESIGN and ASSEMBLY in France",
            },
            {
              name: "Folding / Unfolding",
              value:
                "Handlebar and frame fold independently, quickly and easily",
            },
            {
              name: "Number and type of storage",
              value: "luggage rack",
            },
          ],
        },
        ,
        {
          title: "Technical characteristics",
          array: [
            {
              name: "Frame / Fork",
              value: "Aluminum frame fitted with a hanging fork",
            },
            {
              name: "Suspension / shock absorber",
              value:
                "With its suspension at the front your bike brings you comfort in all circumstances",
            },
            {
              name: "Wheels",
              value: "20 '",
            },
            {
              name: "Tire",
              value: "20''X1.95 / 47-406",
            },
            {
              name: "Rays",
              value: "Spoke wheel",
            },
            {
              name: "Brake (s)",
              value: "Front + rear disc brakes",
            },
            {
              name: "Handlebar / Handle",
              value:
                "The handlebar is compact and fully collapsible right, handful very Res semi-rigid rubber istant",
            },
            {
              name: "Direction / Gallows",
              value: "Height adjustable aluminum stem",
            },
            {
              name: "Number of speeds",
              value: "7 speed",
            },
            {
              name: "Derailleur / Cassette",
              value: "Shimano",
            },
            {
              name: "Saddle / Seatpost",
              value: "Removable seatpost with Comfort saddle",
            },
            {
              name: "Crutch",
              value: "Side stand",
            },
            {
              name: "Stabilizers",
              value: "With stabilizer",
            },
            {
              name: "Horn",
              value: "electric",
            },
            {
              name: "Motor position",
              value: "Rear wheel",
            },
          ],
        },
        {
          title: "Food",
          array: [
            {
              name: "Feed type",
              value: "Drums",
            },
            {
              name: "Drums",
              value:
                "36V 11 Ah Lithium battery, integrated in the frame and removable",
            },
            {
              name: "Autonomy",
              value: "32 to 55Km",
            },
            {
              name: "Engine",
              value: "MECER 36V motor, 350W, flanged to 250W",
            },
          ],
        },
        {
          title: "Dimensions and Weight ",
          array: [
            {
              name: "Dimensions",
              value: "Very low adjustable saddle for children use",
            },
            {
              name: "Weight",
              value: "21Kg with battery",
            },
          ],
        },
        {
          title: "Various",
          array: [
            {
              name: "Mounting",
              value: "Delivered assembled",
            },
            {
              name: "Mounting type",
              value: "The bike is delivered fully assembled folded",
            },
            {
              name: "Usage tips",
              value: "Recommended size: from 1.5M up to 2M",
            },
            {
              name: "Native country	",
              value: "France",
            },
          ],
        },
        {
          title: "Your guarantees included for the products sold by Cdiscount",
          array: [
            {
              name: "Guarantee (²)",
              value: "2 years - parts, labor and travel",
            },
            {
              name: "Observations",
              value:
                "- See specific terms and conditions in the GTC for second-hand products. - These guarantees are not applicable to products sold for the marketplace. As well as products sold to professionals and / or for professional use. Please refer to the seller's terms and conditions.",
            },
          ],
        },
      ];

      break;

    case 3:
      return [
        {
          title: "General product information",
          array: [
            {
              name: "Mark",
              value: "MECER",
            },
            {
              name: "Product Name",
              value: "MECER City bike 26 'electric - Aluminum frame with",
            },
            {
              name: "Category",
              value: "ELEC ASSISTANCE BIKE",
            },
          ],
        },
        {
          title: "General",
          array: [
            {
              name: "Type of product",
              value: "electric city bike",
            },
            {
              name: "Models",
              value: "MEB-CB-26-W",
            },
            {
              name: "Sports",
              value: "Leisure cycling Road cycling",
            },
            {
              name: "Materials",
              value: "Aluminum",
            },
            {
              name: "Colors",
              value: "Black",
            },
            {
              name: "Certifications and standards",
              value:
                "EN 15194: 2009 + A11: 2011 European standard EPAC EN15194",
            },
            {
              name: "Weight (up to)",
              value: "100 kg",
            },
            {
              name: "Age (From)",
              value: "Adult and child",
            },
            {
              name: "Child size",
              value: "150 cm",
            },
          ],
        },
        {
          title: "Product Info",
          array: [
            {
              name: "Frame size",
              value: '26 " aluminum frame',
            },
            {
              name: "Wheel sizes",
              value: '26 "',
            },
            {
              name: "Type of bike",
              value: "VAE",
            },
            {
              name: "Compatible land (s)",
              value: "Asphalt Paths and trails",
            },
          ],
        },
        {
          title: "Characteristics",
          array: [
            {
              name: "Product Description",
              value:
                "MECER Electrically assisted city bike, aluminum frame, 36 V, 11 Ah lithium batteries with hill start assistance and stop lights Maximum speed: 25 Km / h  DESIGN and ASSEMBLY in France",
            },
            {
              name: "More product",
              value:
                "The MECER bike has a trigger for hill start without difficulty whatever your age, LED STOP LIGHTS + powerful LED LIGHTS AV + AR, Removable saddle, luggage rack with spring fixing system. The bike is one of the only ones that can be used by people the size of 1.50m.",
            },
            {
              name: "Number and type of storage",
              value:
                "luggage rack with fastening system, the basket at the front is optional",
            },
            {
              name: "Comfort",
              value: "tilting saddle",
            },
          ],
        },
        ,
        {
          title: "Technical characteristics",
          array: [
            {
              name: "Frame / Fork",
              value: "Aluminum low spanning frame Aluminum suspension fork",
            },
            {
              name: "Suspension / shock absorber",
              value:
                "With its suspension at the front your bike brings you comfort in all circumstances",
            },
            {
              name: "Tire",
              value: "26 inch (26 x 1.5)",
            },
            {
              name: "Brake (s)",
              value: "V brake brakes front and rear pads",
            },
            {
              name: "Handlebar / Handle",
              value:
                "Handlebar type Dutch curved forward and fully adjustable, Handle in semi-rigid rubber very resistant",
            },
            {
              name: "Direction / Gallows",
              value: "Height adjustable aluminum stem",
            },
            {
              name: "Number of speeds",
              value: "7 speed",
            },
            {
              name: "Derailleur / Cassette",
              value: "Shimano 7-speed rear derailleur",
            },
            {
              name: "Pedals / Bottom Bracket",
              value: "Non-slip pedals",
            },
            {
              name: "Saddle / Seatpost",
              value: "Tilting and removable rod Minimum seat height 80 cm",
            },
            {
              name: "Crutch",
              value: "Side stand",
            },
            {
              name: "Stabilizers",
              value: "With stabilizer",
            },
            {
              name: "Horn",
              value: "mechanical",
            },
            {
              name: "Motor position",
              value: "Rear wheel",
            },
          ],
        },
      ];

      break;

    default:
      break;
  }
};
