import React from "react";

import "./SearchBar.css";

const SearchBar = ({ changeSearch, placeHolder }) => {
    return (
        <div className="input-group search-group">
            <div className=" input-group-search">
                <i className="fa fa-search"></i>
            </div>
            <input
                className="form-control searchBar"
                // style={{ border: "0px" }}
                type="search"
                placeholder={placeHolder !== undefined ? placeHolder : "Search"}
                aria-label="Search"
                // value={searchText}
                onChange={(e) => changeSearch(e.target.value)}
            />
        </div>

        // <div className="input-group searchBar">
        //     <div class="input-group-addon">
        //         <i class="fa fa-search"></i>
        //     </div>

        //     <input
        //         className="form-control "
        //         type="search"
        //         placeholder="Search"
        //         aria-label="Search"
        //         // value={searchText}
        //         onChange={(e) => changeSearch(e.target.value)}
        //     />
        // </div>
    );
};

export default SearchBar;
