import React, { useState, useEffect, Component } from 'react';
import Image from 'material-ui-image';
import { CloudinaryContext, Transformation } from 'cloudinary-react';
import { SketchPicker } from 'react-color';
import './Product.css';
import Layout from 'components/Layout/Layout';
import SimpleReactLightbox from 'simple-react-lightbox';
import { SRLWrapper } from 'simple-react-lightbox';
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { productInfo, productDetails } from './ProductInfo';

const Product = (props) => {
    const product = productInfo(parseInt(props.match.params.productId));

    const productDetail = productDetails(
        parseInt(props.match.params.productId)
    );

    const [values, setValues] = useState({
        bikes: product[0].bikes,
        selectedBike: product[0].bikes[0],
    });

    // useEffect(() => {
    //   console.log("test", product);
    // }, [product]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const { bikes, selectedBike } = values;

    const ImageTransformations = ({ width, selectedBike, text }) => {
        return (
            <Image
                src={selectedBike.src}
                width={width}
                animationDuration={500}
            />
        );
    };

    const handleChange = (name) => (event) => {
        console.log('tekst', event.target);
        setValues({ ...values, error: false, [name]: event.target.value });
    };

    const clickSubmit = (key) => {
        console.log('tekst', bikes[key]);
        setValues({ ...values, error: false, selectedBike: bikes[key] });
    };

    const useStyles = makeStyles((theme) => ({
        root: {
            width: '100%',
        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
            fontWeight: theme.typography.fontWeightRegular,
        },
    }));
    const classes = useStyles();

    return (
        <Layout
            title="Product"
            description="Product description"
            className="container col-md-8 offset-md-2"
        >
            <div className="row w-100 m-0 lr">
                {/* <div id="demoContainer">
            <div id="header">
              <a href="http://cloudinary.com/">
                <img
                  width="172"
                  height="38"
                  src="http://res-1.cloudinary.com/cloudinary/image/asset/dpr_2.0/logo-e0df892053afd966cc0bfe047ba93ca4.png"
                  alt="Cloudinary Logo"
                />
              </a>
              <h1>Product Personalization Demo</h1>
            </div>
          </div> */}

                <div className="col-md-12 col-lg-6 mt-5 mb-5 noselect p-0">
                    <div className="col-12 col-sm-8">
                        <SimpleReactLightbox>
                            <SRLWrapper>
                                <Image
                                    src={selectedBike.src}
                                    imageStyle={{
                                        width: '100%',
                                        height: 'inherit',
                                    }}
                                />
                            </SRLWrapper>
                        </SimpleReactLightbox>
                    </div>
                    <div className="w-100"></div>
                    <div className="col-12 col-sm-8">
                        <SimpleReactLightbox>
                            <SRLWrapper>
                                <ul id="thumbs">
                                    {bikes.map((thumb, key) => {
                                        return (
                                            <li
                                                key={key}
                                                className={
                                                    thumb.src ===
                                                    selectedBike.src
                                                        ? 'active'
                                                        : ''
                                                }
                                                onClick={() => clickSubmit(key)}
                                                key={thumb.id}
                                            >
                                                <ImageTransformations
                                                    width="80"
                                                    selectedBike={thumb}
                                                    text={' '}
                                                />
                                            </li>
                                        );
                                    })}
                                </ul>
                            </SRLWrapper>
                        </SimpleReactLightbox>
                    </div>
                </div>
                {product.map((item, index) => {
                    return (
                        <div key={index} className="col-md-12 col-lg-6 mt-5">
                            <div className="title">
                                <span>{item.title}</span>
                            </div>
                            <div className="price-container">
                                <span className="price d-none d-lg-inline">
                                    <del>
                                        <span>{item.price}</span>
                                    </del>{' '}
                                    <ins>
                                        <span>{item.priceAfterDiscount}</span>
                                    </ins>
                                </span>
                            </div>
                            <div className="info-container mt-3 mb-3">
                                <span className="text">{item.model}</span>
                            </div>

                            {/* <button className="btn btn-lg btn-info">More Info</button> */}
                            {/* <div className="inputSelections">
              <h2>Text:</h2>
              <input
                className="form-control"
                type="email"
                placeholder="Enter text"
                //onChange={this.handleTextChange.bind(this)}
                onChange={handleChange("text")}
                value={text}
              />
            </div> */}
                        </div>
                    );
                })}

                {product.map((item, index) => {
                    return (
                        <div key={index} className="col-sm-12">
                            <h4>{item.descriptionTitle}</h4>
                            <p>{item.descriptionText}</p>
                        </div>
                    );
                })}

                {/* <div class="container">
          <div class="w-100">
            <b>test</b>
          </div>
          <div class="row">
            <div class="col py-2 px-lg-5 border bg-light">
              Custom column padding
            </div>
            <div class="col-8 py-2 px-lg-5 border">Custom column padding</div>
          </div>
          <div class="row">
            <div class="col py-2 px-lg-5 border bg-light">
              Custom column padding
            </div>
            <div class="col-8 py-2 px-lg-5 border">Custom column padding</div>
          </div>
        </div> */}

                <div className={classes.root}>
                    <ExpansionPanel>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography className={classes.heading}>
                                {/* <button className="btn btn-lg btn-info">More Info</button> */}
                                <b>{' More Info'}</b>
                            </Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Typography>
                                <table className="table table-md">
                                    {productDetail.map((item, index) => {
                                        return (
                                            <tbody key={index}>
                                                <tr>
                                                    <th colspan="2">
                                                        {item.title}
                                                    </th>
                                                </tr>
                                                {item.array.map((c, i) => (
                                                    <tr key={i}>
                                                        <td
                                                            className="table-active"
                                                            scope="col"
                                                        >
                                                            {c.name}
                                                        </td>
                                                        <td>{c.value}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        );
                                    })}
                                </table>
                            </Typography>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                </div>
            </div>
        </Layout>
    );
};

export default Product;
